// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetPartnersData,
    DeletePartnerById,
    UpdatePartnersPositions
} from "../../redux/actions";


class Partner extends Component {
    constructor(props) {
        super(props);
        this.deleteItem = deleteItem.bind(this, props.DeletePartnerById, 'Գործընկերը');
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
        this.props.GetPartnersData();
    }

    getNestableItems(list,) {
        const items = [];
        const {mainLanguage} = this.props;
        // eslint-disable-next-line no-unused-expressions
        !!list?.length && list?.forEach((item, index) => {
            const {title = ' - '} = item

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `/partners/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedProjectId = item.id;
        const {partnersData} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~partnersData.findIndex(m => m.id === movedProjectId) &&
            ~positionedItems.findIndex(m => m.id === movedProjectId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdatePartnersPositions(positionedItems);
            this.props.GetPartnersData();
        }
    }

    render() {
        let {partnersData} = this.props;
        let items = this.getNestableItems(partnersData);
        return <PageWrapper pageTitle={'Գործընկերներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/partners/add"}/>
            {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    maxDepth={1}
                    onChange={this.onPositionChange}
                    renderItem={NestableItem}
                />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetPartnersData,
    DeletePartnerById,
    UpdatePartnersPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Partner);
